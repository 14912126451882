import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthenticationService} from '../../../swagger/generated/services/authentication.service';
import {AuthenticationRequest} from '../../../swagger/generated/models/authentication-request';
import {UserDto} from '../../../swagger/generated/models/user-dto';
import {ActivatedRoute, Router} from '@angular/router';
import {UserStoreService} from '../../shared/stores/user-store/user-store.service';
import {Message} from 'primeng/api';
import {CommonUtilsService} from '../../shared/services/utils/common-utils/common-utils.service';
import {UploadService} from "../../../swagger/generated/services/upload.service";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  isLogin = true;
  loginRequest: AuthenticationRequest = {};
  userDto: UserDto = {};
  errorMessages: Array<Message> = [];
  @Input() innerComponent = false;
  @Output() loginEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  isSuccessful = false;

  constructor(
    private authenticationService: AuthenticationService,
    private userStore: UserStoreService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private commonUtils: CommonUtilsService,
    private uploadService: UploadService,
  ) {
  }

  private isRegisterFormValid(): boolean {
    this.errorMessages = [];
    if (this.commonUtils.isEmptyOrNull(this.userDto.displayName)) {
      this.errorMessages.push({summary: 'Display name is missing', severity: 'error'});
    }
    if (this.commonUtils.isEmptyOrNull(this.userDto.login)) {
      this.errorMessages.push({summary: 'Login is missing', severity: 'error'});
    }
    if (this.commonUtils.isEmptyOrNull(this.userDto.email)) {
      this.errorMessages.push({summary: 'Email is missing', severity: 'error'});
    }
    if (this.commonUtils.isEmptyOrNull(this.userDto.password)) {
      this.errorMessages.push({summary: 'Password is missing', severity: 'error'});
    }

    return this.errorMessages.length === 0;
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.tokenExpired) {
      this.errorMessages.push({summary: 'Your session has been expired, please login again', severity: 'error'});
    }
  }
  registerDrive(){
    this.uploadService.doGoogleSignIn().subscribe(resp=> {
      if (resp) {
        window.location.href = resp;
      }
    })
  }
  register() {
    if (this.isRegisterFormValid()) {
      this.authenticationService.register(this.userDto).subscribe(
        (res) => {
          this.userStore.setConnectedUser(res.user);
          this.userStore.setAccessToken(res.token);
          if (this.innerComponent) {
            this.loginEvent.emit(true);
          } else {
            this.isSuccessful = true;
            this.registerDrive();
            // this.router.navigate(['/user/home']);
            // this.errorMessages.push({
            //    summary: 'Your registration is successful! \n Now you can login with your email to your account',
            //    severity: 'success'
            //  });
          }
        },
        (error) => {
          const errorMsg = error.error.errorMessage === 'Please check all the inputs' ? 'Please check all the inputs' : 'User already exists';
          this.errorMessages.push({summary: errorMsg, severity: 'error'});
        }
      );
    }
  }

  logIn() {
    this.errorMessages = [];
    this.authenticationService.authenticate(this.loginRequest).subscribe(
      (res) => {
        if (res.user.enabled) {
          this.userStore.setConnectedUser(res.user);
          this.userStore.setAccessToken(res.token);
          if (this.innerComponent) {
            this.loginEvent.emit(true);
          } else {
            // if (res.user.role != "candidate") {
            //   this.registerDrive();
            // } else {
            //   this.router.navigate(['user/home']);
            // }
            this.router.navigate(['user/home']);
          }
          localStorage.removeItem('anonymousUser');
        } else {
          this.errorMessages.push({summary: 'you should check your email and verify your account', severity: 'error'});
        }
      },
      (error) => {
        if (error.status === 404) {
          this.errorMessages.push({summary: 'This user does not exist', severity: 'error'});
        } else if (error.status === 403) {
          this.errorMessages.push({summary: 'Login and / or password are incorrect', severity: 'error'});
        }
      }
    );
  }

  switchToRegister() {
    this.errorMessages = [];
    this.isLogin = !this.isLogin;
  }

  performLogin(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.logIn();
    }
  }

  performRegister(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.register();
    }
  }

}
